import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Ikke funnet :/" description={""}/>

    <div>

      <section className="section novi-bg novi-bg-img section-lg bg-white section-top-shadow">
        <div className="container">
          <h2 style={{marginBottom:'30px'}} className="text-center text-sm-left">Innhold <span className="h2-style">ikke funnet</span></h2>

          <p className="infoPageParagraph">
            Innholdet du leter etter finnes ikke...
          </p>

          <p className="infoPageParagraph">
            Vi sletter fortløpende tilbud som ikke
            er gyldige lenger.
          </p>

          <div className="mt-xl-40 mt-50 text-center text-sm-right"><Link
            className="button button-icon button-icon-right button-black" to="/"><span
            className="icon novi-icon fa fa-chevron-right"/>Til forsiden</Link></div>
        </div>
      </section>

    </div>
  </Layout>
)

export default NotFoundPage
